import helperServices from "../helper-services.js";
export function useWellLogs(){
    async function getLogs(wellId,logType){
        try{
            const logDetails = await helperServices.getIndexDetails(wellId);
            if(logDetails.data.logs[logType]){
                const tags=logDetails.data.logs[logType].tags;
                const displayTags =logDetails.data.logs[logType].displayTags
                const units=logDetails.data.logs[logType].units;
                return {tags,displayTags,units};
            }
            return null;
        }
        catch(err){
            return null;
        }
    }
    return{
        getLogs
    }
}